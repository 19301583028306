.table-title {
    font-weight: bold;
    margin: 20px 0;
}
table.page1 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
.page1 td, .page1 th {
    padding: 10px;
    width: 33.33%;
}
.page1 tr:nth-child(even) {
    background-color: #F0F0F0;
}
.page1 tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.navbar {
    width: 1300px;
    height: 100px;
    background-color: #c9c5c5;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
