body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.product-items {
  font-size: 13px;
}

.ready {
  text-decoration: underline;
}



.button {
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}
.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background:  rgb(240, 156, 56);
}
.button.progress::before {
  animation: progress 0.5s ease-in-out forwards;
}
@keyframes progress {
  0% {
    left: -100%;
  }
  10% {
    left: -97%;
  }
  20% {
    left: -92%;
  }
  30% {
    left: -82%;
  }
  30% {
    left: -62%;
  }
  40% {
    left: -38%;
  }
  50% {
    left: -18%;
  }
  60% {
    left: -14%;
  }
  80% {
    left: -7%;
  }
  90% {
    left: -3%;
  }
  100% {
    left: 0%;
  }
}
.button .text-icon {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button .text-icon i,
.button .text-icon span {
  position: relative;
  color: #fff;
  font-size: 26px;
}
.button .text-icon span {
  font-size: 20px;
  font-weight: 400;
  margin-left: 8px;
}