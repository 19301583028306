


.navbar {
    width: 1300px;
    height: 100px;
    background-color: #c9c5c5;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sub-title {
    font-weight: bold;
    margin: 10px 0;
}
p {
    margin: 5px 0;
}



dt {
  float: left;
  clear: left;
  width: 200px;
  text-align: left;
}
dt::after {
  content: ":";
}
dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.5em 0;
}