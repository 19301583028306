
table.page2 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #F0F0F0; 
}


table.page2 td, table.page2 th {
    padding: 10px;
    vertical-align: top;
    width: inherit;
}
table.page2 td:nth-child(1) {
    width: 25%;
    white-space: nowrap;
}
table.page2 td:nth-child(2) {
    color: grey;
    width: 30%;
}


table.page2 td.static_value {
    color: grey;
    padding-left: 20px;
}

.navbar {
    width: 1300px;
    height: 100px;
    background-color: #c9c5c5;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deselected {
    background-color: rgb(192, 192, 192);
    border: 2px solid transparent;
}
.selected {
    background-color: rgb(240, 156, 56);
    border: 2px solid transparent;
}
.initval {
    border: 2px solid black;
}
.inactive {
    background-color: rgb(192, 192, 192);
    border: 2px solid transparent;
    color: grey;
}

.freezed {
    cursor: not-allowed;
}



