


.navbar {
    width: 1300px;
    height: 100px;
    background-color: #c9c5c5;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sub-title {
    font-weight: bold;
    margin: 10px 0;
}
p {
    margin: 5px 0;
}

.card-container {
    display: flex;
    flex-wrap: wrap;  /* Erlaubt den Umbruch von Elementen */
    justify-content: flex-start;  /* Ausrichtung am Anfang */
}

.card {
    
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 5px;
    margin: 10px;
    overflow: hidden;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
    text-align: left;

}


.card img {
    width: 100%;
    object-fit: cover;
}

.card-body {
    padding: 0.5rem 1.5rem;
}



.card-border {
    width: calc(25% - 20px);  /* Jede Karte nimmt 25% der Breite des Containers ein, minus 20px Abstand */
    border: 2px solid transparent;
}

.card-border:hover {
    border: 2px solid rgb(240, 156, 56);
  }

 .highlight {
    border: 2px solid rgb(240, 156, 56);
  }

.card-title {
    font-size: 0.95rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 10px;
    padding-left: 0;
    color: #212529;
}

.card-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.card-list {
    padding: 0;
    margin: 0;
    padding-left: 18px;
}

.card-list li {
    padding: 5px;
    color: #6c757d;
}

.card-list li:first-child {
    border-top: none;
}


.card-list li:before {
    content: '\2713';
    color: green;
    margin-left: -23px;
    margin-right: 10px;
}

hr {
    margin: 20px 0;
}



.product-item {
    
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
    text-align: left;

}

table.product-items thead tr th {
    font-weight: bold;
    padding: 10px;
    vertical-align: top;
    text-align: left;
}

table.rohr th.frc,
table.rohr td.frc {
  min-width: 75px;
  
}

table.rohr th.diameter,
table.rohr td.diameter {
  min-width: 100px;
}

table.rohr th.thickness,
table.rohr td.thickness {
  min-width: 100px;
}

table.rohr th.insulation-thickness, 
table.rohr td.insulation-thickness {
  min-width: 100px;
}

table.rohr th.insulation-length, 
table.rohr td.insulation-length {
  min-width: 120px;
}

table.rohr th.products, 
table.rohr td.products {
  min-width: 220px;
}

table.rohr th.certification, 
table.rohr td.certification {
  min-width: 100px;
}

table.rohr th.insulation,
table.rohr td.insulation {
  min-width: 100px;
  max-width: 200px;
}

table.rohr th.penetration, 
table.rohr td.penetration {
  min-width: 150px;
}

table.product-items tbody tr:hover {
    background-color: #afffc8;
}


table.kabel thead tr th.diameter-bundle,
table.kabel tbody tr td.diameter-bundle {
  width: 100px;
}

table.kabel thead tr th.diameter-elec-cond,
table.kabel tbody tr td.diameter-elec-cond {
  width: 100px;
}

table.kabel thead tr th.diameter-single-cable,
table.kabel tbody tr td.diameter-single-cable {
  width: 100px;
}

tr.hit-accurancy-100 td {
  background-color: rgb(234, 244, 234)
}

tr.hit-accurancy-100:hover td {
  background-color: #98f7b6;
}

tr.highlight-item td{
  background-color: #98f7b6;
}

tr.hit-accurancy-50 td {
    
}