body {
  background-color: #FFFFFF;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
  padding: 0;
}

.hensel-logo {
  margin-top: 20px;
  width: 200px;
}

.magicad-logo {
  padding-left: 20px;
  width: 300px;
}

.revit-logo {
  margin-bottom: 10px;
  padding-left: 20px;
  width: 60px;
}

.content-area {
  width: 1300px;
  background-color: #F0F0F0;
  margin: 0 auto;
  padding: 20px 30px;
  box-sizing: border-box;
  padding-bottom: 120px;
}

header {
  background-color: #F0F0F0;
  height: 25px;
  margin: 20px 0;
  font-size: 24px;
  color: #000000;
  font-weight: bold;
}

.table-title {
  font-weight: bold;
  margin: 20px 0;
}

.continue-btn, .back-btn, .btn {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  background: #FFFFFF;
  border: none;
  cursor: pointer;
  margin: 5px 10px;
  padding: 8px 16px;
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: fit-content;
}
.continue-btn, .back-btn {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}


.btn {
  width: 150px;
}

footer {
  height: 100px;
  background-color: #F0F0F0;
  font-size: 12px;
  color: #000000;
  text-align: center;
  line-height: 100px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
td, th {
  padding: 10px;
  width: 33.33%;
}

.btn-half-size {
  width: 235px;
}